.List {
    height: 100%;
    /* TODO : Sure ? */
    /*margin-left: 32px;*/

    display: flex;
    flex-direction: column;
}

.List-portrait {
    width: 100%;
    height: 80px;

    margin-top: 8px;
    margin-left: 0;
}

.List-filters {

}

.List-list {
    flex: 1;
    overflow: hidden;
}

.List-list > div + div {
    opacity: 1;
}

.List-noentry {
    background-color: rgba(0,0,0,0.2);
    color: white;
    padding: 8px;
    margin: 32px auto;

    width: 80%;
    height: 38px;
    border-radius: 19px;
    text-align: center;
}

.List-entry {
    height: 68px;
    border-top-left-radius: 34px;
    border-bottom-left-radius: 34px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.0) 100%);
    margin: 0 0px 4px 8px;
    padding: 2px;

    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;

    cursor: pointer;
}

.List-entry-selected {
    background: #EFEACA;
    color: #27394D;
}

.List-entry-uncaught {
    opacity: 0.7;
}

.List-entry-selectionmode {
    opacity: 0.9;
}

.List-entry-selectionmode:hover {
    opacity: 1;
}

.List-entry-thumb {
    background-repeat: no-repeat;
    background-position: 40% 30%;
    background-size: 200% 200%;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid #4D4E50;
}

.List-entry-text {
    flex: 1;
    margin-left: 8px;
    overflow: hidden;
}

.List-entry-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.List-entry-subtext {
    font-size: 0.75em;
}

.List-entry-captured {
    width: 32px;
    height: 32px;

    background-image: url("./images/captured.png");
}

.List-entry-captured-perfect {
    background-image: url("./images/captured_perfect.png");
}

.List-filters {
    margin: 0 4px 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.List-filters-button {
    height: 40px;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: rgba(0,0,0,0.6);

    color: white;
    font-weight: bold;
    cursor: pointer;
}

.List-filters-button > * {
    vertical-align: middle;
}

.List-filtersmenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;

    background-color: rgba(0,0,0,0.6);
}

.List-filtersmenu-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 90%;
    max-width: 750px;
    height: 90%;

    background-color: #F3EFD4;
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.List-filtersmenu-title {
    padding: 8px;

    background-color: #324357;
    color: white;
    font-weight: bold;
}

.List-filtersmenu-list {
    flex: 1;
    padding: 8px;

    overflow: auto;
}

.List-filtersmenu-filter {
    margin-bottom: 16px;
}

.List-filtersmenu-filter-header {
    display: flex;
    width: 100%;
    align-items: center;

    padding: 4px 8px;
    margin-bottom: 4px;
    border-bottom: 1px dashed #bbbbbb;

    cursor: pointer;
}

.List-filtersmenu-filter-title {
    flex: 1;

    font-weight: bold;
    font-size: 1.2em;
    color: #344E65;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.List-filtersmenu-filter-expand {
    fill: #344E65;

    transition: transform .4s ease-in-out;
}

.List-filtersmenu-filter-closed .List-filtersmenu-filter-expand {
    transform: rotate(180deg);
}

.List-filtersmenu-filter-options {
    display: flex;
    flex-wrap: wrap;

    max-height: 300px;
    transition: max-height .4s ease-in;
    overflow: hidden;
}

@media screen and (min-width: 450px) {
    .List-filtersmenu-filter-options {
        max-height: 200px;
    }
}

@media screen and (min-width: 550px) {
    .List-filtersmenu-filter-options {
        max-height: 150px;
    }
}

@media screen and (min-width: 800px) {
    .List-filtersmenu-filter-options {
        max-height: 100px;
    }
}

.List-filtersmenu-filter-closed .List-filtersmenu-filter-options {
    max-height: 0;
    transition: max-height .4s ease-out;
}

.List-filtersmenu-filter-option {
    height: 30px;
    margin: 4px;
    padding: 4px 12px;
    border-radius: 15px;

    color: #344E65;
    border: 1px solid #344E65;

    cursor: pointer;

    transition: background-color .25s ease-in-out, color .25s ease-in-out;
}

.List-filtersmenu-filter-option-enabled {
    background-color: #344E65;
    color: white;
}

.List-filtersmenu-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    padding: 8px 0;
}

.List-filtersmenu-button {
    height: 46px;
    margin: 4px;
    padding: 12px;
    border-radius: 8px;
    min-width: 25%;

    background-color: #344E65;
    color: white;
    font-weight: bold;
    text-align: center;

    cursor: pointer;
}

.List-filtersmenu-button-reset {
    background-color: #E74946;
}