.Display {
    width: 100%;
    height: 100%;

    position: relative;
    flex: 1;

    border-radius: 16px;

    background-color: #25374B;
    padding: 8px;

    display: flex;
    flex-direction: column;
}

.Display-inner {
    flex: 1;
    min-height: 1px;

    border-radius: 6px;
    /*background: linear-gradient(90deg, rgba(229,224,192,1) 0%, rgba(210,207,176,1) 3%, rgba(222,217,188,1) 4%, rgba(241,239,216,1) 7%, rgba(241,239,216,1) 76%, rgba(247,244,213,1) 100%);*/
    background-color: #F3EFD4;

    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 10;
}

.Display-selector {
    display: flex;
    align-items: flex-end;

    margin-left: 16px;
}

.Display-tab {
    height: 38px;
    width: 54px;
    margin: 0 -4px;

    background-color: #F3EFD4;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    cursor: pointer;

    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.28);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Display-tab-selected {
    height: 44px;
    width: 66px;

    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;

    z-index: 2;
}

.Display-processing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.6);

    z-index: 20;
}

.Display-processing-status {
    position: absolute;
    bottom: 50%;
    margin-bottom: 8px;
    width: 100%;

    text-align: center;
    color: white;
}

.Display-processing-bar {
    width: 60%;
    height: 16px;

    border-radius: 8px;
    overflow: hidden;
    /* TODO : Improve that ! */
    border: 1px solid white;

    position: absolute;
    top: 50%;
    left: 20%;
}

.Display-processing-bar-inner {
    position: relative;
    width: 0;
    height: 100%;

    /* TODO : Improve that ! */
    background-color: white;
}

.Display-processing-infinite .Display-processing-bar-inner {
    width: 4px;

    animation: move 2s linear 0s infinite alternate;
}

@keyframes move {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}

.Display-camera-target {
    position: absolute;
    top: 10%;
    height: 80%;
    left: 10%;
    width: 80%;

}

.Display-camera-target > div {
    position: absolute;
}

.Display-camera-target > div:first-child {
    top: 0;
    left: 0;
    width: 20%;
    height: 20%;

    border-top: 1px solid red;
    border-left: 1px solid red;
}

.Display-camera-target > div:nth-child(2) {
    top: 0;
    right: 0;
    width: 20%;
    height: 20%;

    border-top: 1px solid red;
    border-right: 1px solid red;
}

.Display-camera-target > div:nth-child(3) {
    bottom: 0;
    left: 0;
    width: 20%;
    height: 20%;

    border-bottom: 1px solid red;
    border-left: 1px solid red;
}

.Display-camera-target > div:last-child {
    bottom: 0;
    right: 0;
    width: 20%;
    height: 20%;

    border-bottom: 1px solid red;
    border-right: 1px solid red;
}

.Display-camera-message {
    position: absolute;

    bottom: 12%;
    left: 50%;
    width: 76%;
    max-width: 600px;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.7);
    color: white;

    border-radius: 16px;
    padding: 16px;

    transform: translateX(-50%);
    overflow: hidden;
}

.Display-camera-message-text {
    opacity: 0;

    transition: opacity .4s ease-in-out;
}

.Display-possibilities {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.6);

    z-index: 20;
}

.Display-possibilities-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 360px;
    max-width: 90%;

    background-color: #F3EFD4;
    border-radius: 8px;
    overflow: hidden;
}

.Display-possibilities-title {
    padding: 8px;

    background-color: #324357;
    color: white;
    font-weight: bold;
}

.Display-possibilities-list {
    padding: 8px;

    max-height: 90vh;
}

/* Infos */
.Infos {
    height: 100%;
    width: 100%;

    overflow: auto;
}

.Infos-header {
    padding: 6px 4px;
    margin: 16px;
    background-color: #324357;

    display: flex;
    align-items: center;

    color: #F5FAFD;
    font-weight: bold;
}

.Infos-favorite {
    width: 28px;
    height: 28px;

    background: url("./images/favorites_inactive.png") no-repeat center center;

    margin: 0 4px;

    cursor: pointer;
}

.Infos-favorited {
    width: 28px;
    height: 28px;

    background: url("./images/favorites_activated.png") no-repeat center center;

    margin: 0 4px;

    cursor: pointer;
}

.Infos-number {
    border: 1px solid #687888;
    padding: 4px;
    white-space: nowrap;
}

.Infos-name {
    flex: 1;
    margin: 0 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Infos-types {
    display: flex;
}

.Infos-type {
    height: 28px;
    border-radius: 14px;
    padding-right: 12px;

    margin: 0 4px;

    background-color: #1D262F;
}

.Infos-type > img {
    max-height: 100%;
    vertical-align: middle;
}

.Infos-type > span {
    vertical-align: middle;
    margin-left: 4px;
}

@media screen and (max-width: 500px) {
    .Infos-type {
        padding: 0;
    }

    .Infos-type > span {
        display: none;
    }
}

.Infos-center {
    /*height: 50%;*/
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 0 16px;
}

.Infos-picture {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Infos-picture-thumb {
    width: 100%;
    max-width: 250px;
    
    border: 22px solid #F4F3EE;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.28);

    background-repeat: no-repeat;
    background-size: contain;
    background-color: #D7D4CB;
}

.Infos-picture-thumb:before {
    content: '';
    position: relative;
    display: block;

    width: 100%;
    padding-bottom: 100%;
    box-sizing: border-box;
}

.Infos-column {
    width: 40%;

    margin-left: 32px;
}

@media screen and (max-width: 450px) {
    .Infos-column {
        width: 100%;

        margin-left: 0;
        margin-top: 8px;
    }
}

.Infos-footer {
    position: relative;
    margin-top: 8px;

    padding: 0 16px;
}

.Infos-description {
    /*width: 580px;*/
    height: 88px;

    border: 1px solid #A9AAA5;
    padding: 16px;

    background-color: #F2EFDC;
    color: #A9AAA5;
}

.Infos-level {
    position: absolute;
    /*width: 320px;
    height: 148px;
    right: -48px;
    top: 0;*/

    border: 1px solid #A9AAA5;
    padding: 16px;

    background-color: #F2EFDC;
    color: #A9AAA5;
}

/* Tasks */
.Tasks {
    height: 100%;
    width: 100%;

    overflow: auto;

    padding: 16px;
}

.Tasks-header {
    padding: 6px 4px;
    margin-bottom: 16px;
    background-color: #324357;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #F5FAFD;
    font-weight: bold;
}

.Tasks-task {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin: 8px 0;
}

.Tasks-task-withmenu {
    z-index: 2;
}

.Tasks-task-value {
    position: relative;
    width: 24px;
    height: 25px;
    border-radius: 4px;

    background-color: #DFDFD3;
}

.Tasks-task-doubled {
    background-color: #E74946;
    /* TODO : icon */
}

.Tasks-task-doubled:after {
    content: '';

    width: 16px;
    height: 17px;

    position: absolute;
    top: 4px;
    left: 4px;

    background-image: url("./images/doubled.png");
    background-repeat: no-repeat;
}

.Tasks-task-name {
    flex: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #344E65;
    font-weight: bold;

    padding: 0 4px;
}

@media screen and (max-width: 500px) {
    .Tasks-task-verylongname {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 400px) {
    .Tasks-task-longname {
        font-size: 0.9em;
    }
    .Tasks-task-verylongname {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 300px) {
    .Tasks-task-longname {
        font-size: 0.8em;
    }
    .Tasks-task-verylongname {
        font-size: 0.7em;
    }
}

.Tasks-task-values {
    width: 310px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 700px) {
    .Tasks-task-values {
        width: 100%;
        max-width: none;
        margin-top: 4px;
    }
}

.Tasks-task-count {
    position: relative;
    width: 40px;
    min-width: 40px;
    text-align: right;
    padding-right: 4px;
    border-right: 1px solid #D4CDBA;
    color: #344E65;
    font-weight: bold;

    cursor: pointer;
    z-index: 2;
}

.Tasks-task-count-withnewvalue {
    cursor: default;
}

.Tasks-task-count-value {

}

.Tasks-task-count-oldValue {
    color: red;

    background-color: #ffb6b6;
    border-radius: 4px;
    padding: 0 4px;
}

.Tasks-task-count-newValue {
    color: green;
    background-color: #b6e7b6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0 4px;

    position: absolute;
    left: 100%;
    top: 0;

    margin-left: 8px;
}

.Tasks-task-count-newValue:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 0;
    border: 8px solid transparent;
    border-right-color: #b6e7b6;
    border-top-width: 10px;
    border-bottom-width: 10px;
}

.Tasks-task-count-menu {
    background-color: #324357;
    color: white;
    border-radius: 4px;
    padding: 0 4px;

    position: absolute;
    left: 50%;
    top: 100%;

    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100px;
    margin-left: -50px;

    z-index: 10;
}

.Tasks-task-count-menu:before {
    content: '';
    position: absolute;
    top: -16px;
    left: 42px;
    border: 8px solid transparent;
    border-bottom-color: #324357;
    border-left-width: 10px;
    border-right-width: 10px;
}

.Tasks-task-count-menu-button {
    font-size: 22px;
    padding: 8px 16px;
}

.Tasks-task-targets {
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Tasks-task-target {
    position: relative;
    width: 40px;
    height: 22px;
    border-radius: 4px;

    background-color: #E7DFB1;
    color: #A2A48E;

    text-align: center;
    margin: 0 4px;
}

.Tasks-task-target-done:after {
    content: '';

    width: 19px;
    height: 18px;

    position: absolute;
    top: -4px;
    left: 0;

    background-image: url("./images/check.png");
    background-repeat: no-repeat;
}

.Tasks-task-target-done-new:after {
    animation: fade 1.6s linear 0s infinite;
}

@keyframes fade {
    0% {
        opacity: 0.4;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.4;
    }
}

.Tasks-newvalues {
    position: absolute;
    z-index: 10;

    bottom: 64px;
    left: 50%;

    background-color: rgba(0, 0, 0, 0.8);
    color: white;

    border-radius: 16px;

    transform: translateX(-50%);
    overflow: hidden;
}

.Tasks-newvalues-label {
    margin: 16px;
}

.Tasks-newvalues-actions {
    display: flex;
    align-items: center;
}

.Tasks-newvalues-button {
    flex: 1;
    text-align: center;
    padding: 16px 4px;

    cursor: pointer;
}

.Tasks-newvalues-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Locations */
.Locations {
    height: 100%;
    width: 100%;

    overflow: auto;

    padding: 16px;
}

.Locations-header {
    padding: 6px 4px;
    margin-bottom: 16px;
    background-color: #324357;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #F5FAFD;
    font-weight: bold;
}

.Locations-zone {
    margin: 8px;
}

.Locations-zone-title {
    background:
            -webkit-radial-gradient(100% 0, circle, rgba(39,57,77,0) 6px, #27394D 7px),
            -webkit-radial-gradient(0 0, circle, rgba(39,57,77,0) 6px, #27394D 7px);
    background-position: top right, top left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #EAE8DC;
    font-weight: bold;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Locations-zone-result {
    background:
            -webkit-radial-gradient(0 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px),
            -webkit-radial-gradient(100% 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px);
    background-position: bottom left, bottom right;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #27394D;
    font-weight: bold;
    text-align: center;
}

.Locations-zone-location {
    border-bottom: 1px dashed #bbbbbb;
    padding: 2px;
}

.Locations-zone-location:last-child {
    border-bottom: none;
}

/* Favorites */
.Favorites {
    height: 100%;
    width: 100%;

    overflow: auto;

    padding: 16px;
}

.Favorites-nofavorite {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #344E65;
    font-weight: bold;
    text-align: center;
}

.Favorites-nofavorite img,
.Favorites-nofavorite span {
    vertical-align: middle;
}

.Favorites-favorite {
    margin: 8px;
}

.Favorites-favorite-title {
    background:
            -webkit-radial-gradient(100% 0, circle, rgba(39,57,77,0) 6px, #27394D 7px),
            -webkit-radial-gradient(0 0, circle, rgba(39,57,77,0) 6px, #27394D 7px);
    background-position: top right, top left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #EAE8DC;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.Favorites-star {
    width: 22px;
    height: 22px;

    background: url("./images/favorites_activated.png") no-repeat center center;
    background-size: cover;
}

.Favorites-name {
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 0 4px;
}

.Favorites-completion {
    width: 22px;
    height: 22px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.Favorites-completed {
    background-image: url("../list/images/captured.png");
}

.Favorites-completed-perfect {
    background-image: url("../list/images/captured_perfect.png");
}

.Favorites-favorite-result {
    background:
            -webkit-radial-gradient(0 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px),
            -webkit-radial-gradient(100% 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px);
    background-position: bottom left, bottom right;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #27394D;
    font-weight: bold;
    text-align: center;
}

.Favorites-favorite-quicktasks {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -.5%;
}

.Favorites-quicktask {
    width: 99%;
    display: flex;
    align-items: center;

    font-size: 0.9em;

    padding: 4px 0;
    margin: 0 .5%;

    border-bottom: 1px dashed #bbbbbb;
}

@media screen and (min-width: 400px) {
    .Favorites-quicktask {
        width: 49%;
    }
}

@media screen and (min-width: 700px) {
    .Favorites-quicktask {
        width: 32%;
    }
}

@media screen and (min-width: 900px) {
    .Favorites-quicktask {
        width: 24%;
    }
}

.Favorites-quicktask-name {
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
}

.Favorites-quicktask-value {
    margin-left: 4px;

    cursor: pointer;
}