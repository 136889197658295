.Dex {
    position: relative;
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow: hidden;
}

.Dex-portrait {
    flex-direction: column;
}

.Dex-list-button {
    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;
}

.Dex-list-menu {
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 20;

    transition: all .4s ease-in-out;

    background-color: rgba(0,0,0,0);

    padding-left: 8px;
}

.Dex-list-menu-open {
    right: 0;

    background-color: rgba(0,0,0,0.6);
}

.Dex-list-menu-inner {
    width: 90%;
    max-width: 325px;
    height: 100%;
    margin-left: auto;
    margin-right: 0;
    padding-top: 4px;

    background-color: #7C7269;
    border-left: 2px solid #544E48;
}

.Dex-list-inline {
    width: 300px;
    height: 100%;
}