.Presentation {
    position: relative;
    width: 100%;
    height: 100%;

    background: #344E65 url("images/tiling.png") repeat;
    box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.28);

    border-radius: 16px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.Presentation-title {
    margin: 32px;
}

.Presentation-landscape .Presentation-title {
    margin: 8px 32px;
}

.Presentation-title > img {
    max-width: 100%;
    max-height: 10vh;
}

.Presentation-results {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.Presentation-result {
    position: relative;
    background-color: #27394D;
    color: #EAE8DC;

    margin: 0 32px;
    padding: 8px;

    font-weight: bold;

    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    white-space: nowrap;
}

.Presentation-result:before {
    content: '';
    position: absolute;
    left: -36px;
    top: 0px;
    border: 18px solid transparent;
    border-right-color: #27394D;
}

.Presentation-result:after {
    content: '';
    position: absolute;
    right: -36px;
    top: 0px;
    border: 18px solid transparent;
    border-left-color: #27394D;
}

.Presentation-zones {
    margin: 32px 16px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;

    flex: 1;
    overflow: auto;
}

.Presentation-landscape .Presentation-zones {
    margin: 8px 16px;
}

.Presentation-zone {
    min-width: 150px;
    width: calc(50% - 16px);
    /*height: 65px;*/

    margin: 8px;
}

.Presentation-zone-title {
    background:
        -webkit-radial-gradient(100% 0, circle, rgba(39,57,77,0) 6px, #27394D 7px),
        -webkit-radial-gradient(0 0, circle, rgba(39,57,77,0) 6px, #27394D 7px);
    background-position: top right, top left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #EAE8DC;
    font-weight: bold;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Presentation-zone-result {
    background:
            -webkit-radial-gradient(0 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px),
            -webkit-radial-gradient(100% 100%, circle, rgba(234,232,220,0) 6px, #EAE8DC 7px);
    background-position: bottom left, bottom right;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    padding: 4px 10px;

    color: #27394D;
    font-weight: bold;
    text-align: center;
}

.Presentation-progression {
    /*background:
            -webkit-radial-gradient(100% 0, circle, rgba(39,57,77,0) 6px, #27394D 7px),
            -webkit-radial-gradient(0 0, circle, rgba(39,57,77,0) 6px, #27394D 7px),
            -webkit-radial-gradient(0 100%, circle, rgba(39,57,77,0) 6px, #27394D 7px),
            -webkit-radial-gradient(100% 100%, circle, rgba(39,57,77,0) 6px, #27394D 7px);
    background-position: top right, top left, bottom left, bottom right;
    background-size: 51% 51%;
    background-repeat: no-repeat;*/

    background-color: #27394D;
    border-radius: 6px;

    padding: 8px 16px;

    color: #EAE8DC;
    font-weight: bold;
    text-align: center;

    margin: 16px;
    width: calc(100% - 32px);
}

.Presentation-landscape .Presentation-progression {
    margin: 8px 32px;

    display: flex;
    align-items: center;

    height: auto;
}

.Presentation-landscape .Presentation-progression > div {
    flex: 1;
}