.Update {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.Update-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 75vmin;
    background-color: #27394D;
    color: #FFFFFF;
    text-align: center;
    padding: 16px;
    border-radius: 8px;
}

.Update-dialog-icon {
    text-align: center;
    margin: 16px 0;
}