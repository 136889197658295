html, body {
    width: 100%;
    height: 100%;

    background-color: #7C7269;
}

* {
    box-sizing: border-box;
    user-select: none;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App {
    position: relative;

    width: 100%;
    height: 100%;
    max-width: 1280px;
    max-height: 720px;
    margin: auto;
}