.Camera {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.Camera-video {
    width: 100%;
    height: 100%;

    background-color: black;
}

.Camera-button {
    position: absolute;

    top: 50%;
    margin-top: -24px;
    right: 16px;

    width: 48px;
    height: 48px;

    border: 1px solid white;
    border-radius: 50%;

    cursor: pointer;

    z-index: 3;
}

.Camera-button:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: white;
}

.Camera-portrait .Camera-button {
    top: auto;
    margin-top: 0;
    right: auto;

    left: 50%;
    margin-left: -24px;
    bottom: 16px;
}

.Camera-upload {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    left: 16px;

    width: 48px;
    height: 48px;

    border: 1px solid white;
    border-radius: 50%;

    cursor: pointer;
    text-align: center;

    z-index: 2;
}

.Camera-portrait .Camera-upload {
    top: auto;
    margin-top: 0;
    left: auto;

    left: 50%;
    margin-left: -24px;
    top: 16px;
}

.Camera-upload-icon {
    color: white;
    font-size: 38px !important;
    line-height: 44px !important;

    cursor: pointer;
}

.Camera-upload-input {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    cursor: pointer;
}

.Camera-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
}